export const STRINGS = {
  BACK: 'Back',
  NOTIFICATIONS: 'Notifications',
  SHIFT_CLAIM: 'A new Shift has been opened, claim it meh.',
  WANT_SHIFTS: 'Want more shifts?',
  PROFILE: 'Profile',
  ADD_SHIFT: '+  Add Shift',
  CALENDAR: 'Calendar',
  LOGIN_STRING:
    'Please Enter Your Phone Number to Access the got_shift Application',
  CANCEL_SHIFT_CONFIRMATION: 'Are you sure you want to cancel the shift?',
  CLAIM_SHIFT_CONFIRMATION: 'Are you sure you want to claim the shift?',
  RECREATE_SHIFT: 'Do you want to recreate same shift?',
  CANCEL_SHIFT: 'Cancel Shift',
  EDIT_RADIUS: 'Edit Radius of Availability',
  FROM_STORE: 'From Store',
  PHONE_NUMBER: 'Phone Number',
  STORE_LOCATION: 'Store Location',
  POSITION: 'Position',
  POSITION_MULTIPLE_SELECTION: 'Position (multiple selection)',
  DATE: 'Date',
  TIME: 'Time',
  RADIUS: 'Radius',
  NOTIFICATION_SETTINGS: 'Notification Settings',
  NEXT: 'Next',
  PREVIOUS: 'Previous',
  THIS_WEEK: 'This Week',
  AVAILABLE: 'Available',
  UNAVAILABLE: 'Unavailable',
  ENTER_RADIUS: 'Enter Radius',
  SAVE: 'Save',
  ADD_SHIFT :'Add Shift',
  LOGOUT: 'Logout',
  CLAIM: 'Claim',
  CANCEL: 'Cancel',
  NO_SHIFTS: 'No Shifts Found.',
  NO_NOTIFICATIONS: 'No Notifications Found.',
  YES: 'Yes',
  NO: 'No',
  FAIL_TO_LOAD: 'Fail To Load Data',
};

export const VALIDATION_MESSAGES = {
  PHONE: 'Please input your phone number!',
};

export const ROUTES = {
  NOTIFICATIONS: '/notifications', // IN FUTURE'/notifications',
  SHIFT_SEARCH: '/search-shifts',
  PROFILE: '/profile',
  SHIFTS: '/shifts',
  ADD_SHIFT: '/add-shift',
  MONTHLY_SHIFTS: '/monthly-shifts',
  CALENDAR: '/calendar',
  LOG_IN: '/login',
  LOGOUT: '/logout',
  LAYOUT: '/',
  EDIT_RADIUS: '/edit-radius',
  AUTHORIZE: '/authorize',
};

export const ROLES_KEYS = {
  MANAGER: 'MANAGER',
  MEMBER: 'MEMBER',
};

export const ROLES = {
  SUPER_ADMIN: { value: 'SUPER_ADMIN', label: 'Super Admin' },
};

export const SHIFT_COLORS = ['#B463F0', '#45AFE6', '#22CCA5'];

export const SHIFT_CLASSES_BY_STATUS = {
  PENDING: 'pending',
  CLAIMED: 'claimed',
  CANCELLED: 'cancelled',
};

export const SHIFT_STATUS = {
  CLAIMED: { value: 'CLAIMED', label: 'Claimed' },
  PENDING: { value: 'PENDING', label: 'Pending' },
  CANCELLED: { value: 'CANCELLED', label: 'Cancelled' },
};

export const RADIUS_OPTIONS = [
  { value: 1, label: '1 mi' },
  { value: 3, label: '3 mi' },
  { value: 5, label: '5 mi' },
  { value: 10, label: '10 mi' },
  { value: 15, label: '15 mi' },
  { value: 20, label: '20 mi' },
  { value: 25, label: '25 mi' },
  { value: 50, label: '50 mi' },
  { value: 75, label: '75 mi' },
  { value: 100, label: '100 mi' },
];

export const GMT_LOGIN_URL =
  'https://greenfenceb2c.b2clogin.com/greenfenceb2c.onmicrosoft.com/B2C_1A_SignUpOrSignInWithPhoneGotShift/oauth2/v2.0/authorize?client_id=baa9dbf9-5851-4c15-9d22-e0c016322612&redirect_uri=https://kroger.gotshift.io/authorize&response_type=code&scope=baa9dbf9-5851-4c15-9d22-e0c016322612%20offline_access&response_mode=query';

export const GMT_LOGOUT_URL =
  'https://greenfenceb2c.b2clogin.com/greenfenceb2c.onmicrosoft.com/b2c_1a_signuporsigninwithphonegotshift/oauth2/v2.0/logout?post_logout_redirect_uri=https://kroger.gotshift.io/authorize';
