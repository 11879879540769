import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import './index.css'
// import App from './App'
import App from './Routes'
import client from './apollo'
import * as serviceWorker from './serviceWorker'
import { AppContextProvider } from './AppContext'
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Sentry.ErrorBoundary fallback={"An error has occured"}>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </Sentry.ErrorBoundary>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      if (window.confirm('New version available! refresh to update your app?')) {
        console.log("RELOADING")
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
  }
};

serviceWorker.register(configuration);