
import * as firebase from "firebase/app"
import "firebase/analytics"

export const firebaseConfig = {
  apiKey: "AIzaSyDg_-r3_j8Em01GVWNha8gkmhJVHRnPzak",
  authDomain: "greenfence-48f14.firebaseapp.com",
  databaseURL: "https://greenfence-48f14.firebaseio.com",
  projectId: "greenfence-48f14",
  storageBucket: "greenfence-48f14.appspot.com",
  messagingSenderId: "143743316443",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const initFirebaseConfig = () => {
  firebase.initializeApp(firebaseConfig)
}

export const firebaseAnalytics = () => firebase.analytics()

export const trackEvent = (event, params) => {
  try {
    if (params) {
      firebase.analytics().logEvent(event, params)
    } else {
      firebase.analytics().logEvent(event)
    }
  } catch (error) {
    console.log('Error log event event: ', event, 'params: ', params)
  }
}

export const getAnalyticsData = () => {
  return JSON.parse(localStorage.getItem('analyticsData')) || {}
}
