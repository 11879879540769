import React, { useState, useEffect, useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { GET_CURRENT_USER } from './graphql/Queries'
import { ROLES_KEYS, ROUTES } from 'common/constants'
import { AppContext } from 'AppContext'
import Callback from './Callback'
import client from 'apollo'
import * as Auth from '.'

function GuardedRoute(props) {
  const { component: Component, path } = props
  const [loading, setLoading] = useState(true)
  const isAuthenticated = Auth.isAuthenticated()
  const { dispatch } = useContext(AppContext)

  useEffect(() => {
    if (isAuthenticated !== undefined && isAuthenticated) {
      client.query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
        .then((res) => {
          const { roles: [role] } = res.data.currentUser
          dispatch({ type: 'CURRENT_USER', data: res.data.currentUser })
          dispatch({ type: 'IS_MANAGER', data: role === ROLES_KEYS.MANAGER })
          dispatch({ type: 'IS_MEMBER', data: role === ROLES_KEYS.MEMBER })
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [dispatch, isAuthenticated])

  return (
    loading ?
      <Route component={Callback} />
      :
      <Route
        path={path}
        render={(props) => {
          if (isAuthenticated !== undefined && !isAuthenticated) {
            return <Redirect to={ROUTES.LOG_IN} />
          }
          return <Component {...props} />
        }}
      />
  )
}

export default GuardedRoute
