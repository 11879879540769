import React from 'react'
import { Row, Col, Badge } from 'antd'
import { get } from 'lodash'
import { ROUTES } from 'common/constants'
import { GET_UNREAD_COUNT } from 'modules/notifications/graphql/Queries'
import Link from './components/Link'
import { useQuery } from '@apollo/react-hooks'

const { NOTIFICATIONS, PROFILE, CALENDAR } = ROUTES

const Footer = () => {
  const {
    data: {
      unreadCount = 0
    } = {},
  } = useQuery(GET_UNREAD_COUNT, {
    fetchPolicy: 'network-only'
  })

  const userDataObj = JSON.parse(localStorage.getItem('userData'))
  const logoImgUrl = get(userDataObj, 'client.logos.en-US.Large')
  const defaultUrl = process.env.REACT_APP_GMT_LOGO_URL

  return <Row align="middle">
    <Col flex="2" className="height-75">
      <img src={logoImgUrl ? logoImgUrl : defaultUrl}
        className="footer-logo-img" alt="logo" />
    </Col>
    <Col flex="3">
      <Row justify="space-around">
        <Link to={CALENDAR} icon='icon-store-notification' />
        <Link to={PROFILE} icon='icon-profile-header' />
        <Badge count={unreadCount}>
          <Link to={NOTIFICATIONS} icon='icon-bell-header' />
        </Badge>
      </Row>
    </Col>
  </Row>
}

export default Footer