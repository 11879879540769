import React, { useEffect } from 'react'
import { Col, Spin, Card } from 'antd'
import { get } from 'lodash'
import PageWrapper from 'components/page-wrapper'
import { VERIFY_CODE, VERIFY_CODE_DUMMY_LOGIN } from '../auth/graphql/Mutations'
import { useMutation } from '@apollo/react-hooks'
import { trackEvent } from '../../FirebaseConfig'
import history from '../../history'
import { authLogout } from '../auth'

const Authorize = (props) => {
  const { location: { search } } = props
  let isFirebase = process.env.REACT_APP_LOGIN_METHOD === "firebase" ? true : false
  const [verifyCode, { loading }] = useMutation(isFirebase ? VERIFY_CODE_DUMMY_LOGIN : VERIFY_CODE)

  useEffect(() => {
    if (search && search.substr(search.indexOf('code=') + 5, search.length)) {
      const code = search.substr(search.indexOf('code=') + 5, search.length)
      getAuthenticate(code)
    } else {
      history.push('/login', { isInit: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClusterName = (clusters) => {
    const clusterArr = clusters.map((cluster) => cluster.name)
    return clusterArr.join(", ")
  }

  const setAnalyticsData = (verifyCodeObj) => {
    const analyticsData = {
      name: `${get(verifyCodeObj, 'user.first_name')} ${get(verifyCodeObj, 'user.last_name')}`,
      client: get(verifyCodeObj, 'client.name'),
      role: get(verifyCodeObj, 'user.roles')[0],
      store_id: get(verifyCodeObj, 'user.retail_location.store_num'),
      store_name: get(verifyCodeObj, 'user.retail_location.description'),
      district: get(verifyCodeObj, 'user.district'),
      cluster: get(verifyCodeObj, 'user.retail_location.clusters') !== null ? getClusterName(get(verifyCodeObj, 'user.retail_location.clusters')) : null
    }
    localStorage.setItem('analyticsData', JSON.stringify(analyticsData))
    if (verifyCodeObj.is_signup) {
      trackEvent('sign_up', analyticsData)
    } else {
      trackEvent('sign_in', analyticsData)
    }
  }

  const getAuthenticate = (code) => {
    const variables = {
      code: code
    }
    //only for local purpose to run the project. 
    if (isFirebase) {
      verifyCode({ variables }).then(res => {
        if (res && res.data && res.data.dummyLogin) {
          const verifyCodeObj = res.data.dummyLogin
          if (verifyCodeObj && verifyCodeObj.token) {
            localStorage.setItem('token', verifyCodeObj.token)
            localStorage.setItem('userData', JSON.stringify(verifyCodeObj))
            setAnalyticsData(verifyCodeObj)
            history.push('/', { isInit: true })
          }
        }
      }).catch((error) => {
        console.log('verifyCode error: ', error)
        setTimeout(() => {
          authLogout()
        }, 2000);
      })
    } else {
      verifyCode({ variables }).then(res => {
        if (res && res.data && res.data.verifyCode) {
          const verifyCodeObj = res.data.verifyCode
          if (verifyCodeObj && verifyCodeObj.token) {
            localStorage.setItem('token', verifyCodeObj.token)
            localStorage.setItem('userData', JSON.stringify(verifyCodeObj))
            setAnalyticsData(verifyCodeObj)
            history.push('/', { isInit: true })
          }
        }
      }).catch((error) => {
        console.log('verifyCode error: ', error)
        setTimeout(() => {
          authLogout()
        }, 2000);
      })
    }
  }

  return <PageWrapper>
    <Spin spinning={loading}>
      <Col justify="center" align="middle">
        <Card style={{ textAlign: 'center', marginTop: '150px' }}>
          <h1>Please wait....</h1>
        </Card>
      </Col>
    </Spin>
  </PageWrapper>
}

export default Authorize